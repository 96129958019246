<template>
  <div>
    <div v-if="availableSignNumber">
      <van-cell-group>
        <template #title>
          <div style="display:flex;justify-content:space-between">
            <div>基本信息</div>
            <van-button type="info" plain size="mini" @click="handleRiZhi">日志</van-button>
          </div>
        </template>
        <van-cell title="试块类型" value="车辙成型试块"></van-cell>
        <van-cell title="试块时间" :value="model.signTime"></van-cell>
      </van-cell-group>
      <van-collapse v-model="activeNames">
        <van-collapse-item title="车辙" name="1">
          <van-cell title="试验时间"></van-cell>
          <van-cell title="工作方式"></van-cell>
          <van-cell title="试验系数"></van-cell>
          <van-cell title="运行时长"></van-cell>
          <van-cell title="相对位移1"></van-cell>
          <van-cell title="相对位移2"></van-cell>

        </van-collapse-item>
        <van-collapse-item title="燃烧炉" name="2">
          <van-cell title="试验时间"></van-cell>
          <van-cell title="燃烧时长"></van-cell>
          <van-cell title="设置的炉膛温度"></van-cell>
          <van-cell title="混合材料类型"></van-cell>
          <van-cell title="试样重量"></van-cell>
          <van-cell title="损失重量"></van-cell>
        </van-collapse-item>
      </van-collapse>
    </div>
    <el-empty v-else description="验证失败"></el-empty>
    <van-popup v-model="showRiZhi" position="left" close-icon="close" closeable :style="{ width: '70%',height:'100%' }">
      <div style="display:flex;justify-content: center;margin-top: 5px;">
        <van-button size="mini" type="info" plain @click="addRiZhi">增加日志</van-button>
      </div>
      <van-steps direction="vertical" :active="active" @click-step="handleClickStep">
        <van-step v-for="(item,index) in riZhiList" :key="index">
          <div style="display:flex;justify-content:space-between;align-items: center;">
            <div>
              <h4 style="word-break:break-all">{{item.riZhiContent}}</h4>
              <p>{{ item.createTime }}</p>
            </div>
            <van-button round icon="delete-o" type="danger" size="mini" @click="delRiZhi(index)"></van-button>
          </div>
        </van-step>
      </van-steps>
    </van-popup>
    <van-dialog v-model="showRiZhiEdit" title="增加日志" showCancelButton @confirm="handleConfirm">
      <van-field placeholder="请输入日志内容" v-model="riZhiContent"></van-field>
    </van-dialog>
  </div>
</template>

<script>
import Vue from "vue";
import Vant from "vant";

Vue.use(Vant);
export default {
  data() {
    return {
      active: 0,
      showRiZhiEdit: false,
      showRiZhi: false,
      availableSignNumber: false,
      model: {},
      riZhiList: [],
      riZhiContent: "",
      activeNames: [],
    };
  },
  props: ["customerSignNumber"],
  methods: {
    delRiZhi(index) {
      let that = this;
      that.$dialog
        .confirm({ title: "提示", message: "确定要删除吗？" })
        .then(() => {
          that.axios
            .post("CZCX01_CustomerSignNumber_RiZhi/DelModel", {
              onlyInt: that.riZhiList[index].id,
            })
            .then((response) => {
              if (response.data.code == 101) {
                that.riZhiList.splice(index, 1);
              }
            });
        })
        .catch(() => {
          // on cancel
        });
    },
    handleClickStep(index) {
      let that = this;
      that.active = index;
    },
    handleConfirm() {
      let that = this;
      if (that.riZhiContent) {
        that.axios
          .post("CZCX01_CustomerSignNumber_RiZhi/AddModel", {
            docId: that.model.id,
            signNumber: that.model.signNumber,
            riZhiContent: that.riZhiContent,
          })
          .then((response) => {
            that.riZhiList.unshift(response.data.data);
          });
      }
    },
    addRiZhi() {
      let that = this;
      that.riZhiContent = "";
      that.showRiZhiEdit = true;
    },
    handleRiZhi() {
      let that = this;
      let loading = that.$loading({
        lock: true,
        text: "加载中",
        spinner: "el-icon-loading",
      });
      that.axios
        .post("CZCX01_CustomerSignNumber_RiZhi/GetList", {
          onlyInt: that.model.id,
        })
        .then((response) => {
          that.riZhiList = response.data.data;
          loading.close();
          that.showRiZhi = true;
        });
    },
  },
  mounted() {
    document.title = "车辙成型试块";
    let that = this;
    that.axios.post("CustomerSignNumber/CheckCZCX01CustomerSignNumber", { onlyText: that.customerSignNumber }).then((response) => {
      if (response.data.data == null) {
        that.availableSignNumber = false;
      } else {
        that.availableSignNumber = true;
        that.model = response.data.data;
      }
    });
  },
};
</script>

<style scoped>
</style>